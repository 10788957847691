import React,{useState,useRef, useEffect} from "react";
// import {ToastContainer} from "react-toastr";
import { Switch, BrowserRouter as Router } from "react-router-dom";
import { connect } from "react-redux";
import 'custom.css'

// Import Routes all
import { authProtectedRoutes, publicRoutes } from "./routes";

// Import all middleware
import Authmiddleware from "./routes/route";

// layouts Format
import VerticalLayout from "./components/VerticalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";
import { loginSuccess, logoutUser } from "./store/auth/login/actions";
import { getMyProfile } from './store/myProfile/actions'
import { getAllUsers } from "./store/user/actions";
import {getAllPermissions} from './store/permissions/actions'
import { getAllMyPermissions } from './store/myPermissions/actions'
import {useAppValidation } from "./helpers/hooks";
// Import scss
import "./assets/scss/theme.scss";
import io from "socket.io-client";
import immer from "immer";

// const initialMessagesState = {
//   general: [],
// };
// const rooms = ["general"];


function App(props)
{
//   const user = JSON.parse(localStorage.getItem("user"));
//   const socketRef = useRef();
//   const [username, setUsername] = useState();
//   const [currentUser, setCurrentUser] = useState();  
//   const [currentChat, setCurrentChat] = useState({isChannel: true, chatName: "general", receiverId: ""});
//   const [connectedRooms, setConnectedRooms] = useState(["general"]);
//   const [allUsers, setAllUsers] = useState([]);
//   const [messages, setMessages] = useState(initialMessagesState);
//   const [message, setMessage] = useState("");
//   const [messageBox, setMessageBox] = useState(null);
//   const [contacts, setContacts] = useState([]);  

//   const roomJoinCallback=(incomingMessages, room)=>{
//     const newMessages = immer(messages, (draft) => {
//       draft[room] = incomingMessages;
//     });
//     setMessages(newMessages);
//   }
  
//   useEffect(() =>
//   {  console.log(user)
//     if (user)
//     {
//       setUsername(user.username);
//       setCurrentUser({
//         ...user,
//         name: user.username,
//       });  
//       socketRef.current = io.connect("https://api.palazzodip.com");
//   socketRef.current.emit("join server", username);
//   socketRef.current.emit("join room", "general", (messages) => roomJoinCallback(messages, "general"));
//   socketRef.current.on("new user", (allUsers) => {
//     setAllUsers(allUsers);
//   });
//   socketRef.current.on("new message", ({content, sender, chatName, createdAt}) => {
//     setMessages((messages) => {
//       const newMessages = immer(messages, (draft) => {
//         if (draft[chatName]) {
//           draft[chatName].push({content, sender, createdAt});
//         } else {
//           draft[chatName] = [{content, sender, createdAt}];
//         }
//       });
//       return newMessages;
//     });
//   });    
//     console.log("connected", socketRef.current);
//     }
// },[])

  const {
    loginSuccess,    
    getAllUsers,    
    getAllPermissions,
    getAllMyPermissions,
    context,
    logoutUser,
    getMyProfile,
  } = props;  

 const isValidating = useAppValidation(loginSuccess, logoutUser);

  React.useEffect(() => {
    if (context.Login.isAuth) {      
      getAllUsers();     
      getAllPermissions();
      getAllMyPermissions();
      getMyProfile();
    }
  }, [
    context.Login.isAuth,   
    getAllUsers,    
    getAllPermissions,
    getAllMyPermissions,
    getMyProfile,
  ]);  
  
   if (isValidating) {
    return (
      <div id="preloader">
        <div id="status">
          <div className="spinner-chase">
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
          </div>
        </div>
      </div>
    );
  } 
  
let hashHistory = Router.hashHistory;
  return (
    <React.Fragment>
      <Router  >
        <Switch>
          {publicRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
              exact
            />
          ))}

          {authProtectedRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={VerticalLayout}
              component={route.component}
              key={idx}
              isAuthProtected={true}
              exact
              // socketRef ={socketRef}
              // currentChat={currentChat}
              // setCurrentChat={setCurrentChat}
              // connectedRooms={connectedRooms}
              // setConnectedRooms={setConnectedRooms}
              // allUsers={allUsers}
              // setAllUsers={setAllUsers}
              // messages={messages}
              // setMessages={setMessages}
              // message={message}
              // setMessage={setMessage}
              // messageBox={messageBox}
              // setMessageBox={setMessageBox}
              // contacts={contacts}
              // setContacts ={setContacts}  
              // user={user}
              // currentUser={currentUser}
              // rooms={rooms}
              // username={username}
            />
          ))}
        </Switch>
      </Router>
    </React.Fragment>
  );
};


const mapStateToProps = (state) => {
  return {
    context: state,
  };
};

export default connect(mapStateToProps, {
  loginSuccess,
  logoutUser,
  getAllUsers,
  getAllPermissions,
  getAllMyPermissions,
  getMyProfile,
})(App);
