import React, {useEffect} from "react"
import axios from "axios";
import { extractUrlData, getAxiosDefaultConfig, role } from '../../utils';
import {API_URL} from '../../config';
import { Row, Col, Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom"

import avatar from "../../assets/images/users/avatar.png"
import adminImg from "../../assets/images/1_avatar.png"
import chefImg from "../../assets/images/2_avatar.png"
import ownerImg from "../../assets/images/3_avatar.png"

const WelcomeComp = (props) =>
{

  return (
    <React.Fragment>
      <Card className="overflow-hidden">
        <div className="bg-secondary bg-soft">
          <Row>
            <Col xs="8">
              <div className="text-dark p-3">
                <h5 className="text-dark">Welcome {props.currentUser.username}</h5>
                <p>Dashboard</p>
              </div>
            </Col>
            <Col xs="4" className="align-self-end">
              <img
                src={
                  (props.currentUser.roles === parseInt(process.env.REACT_APP_ADMIN) || props.currentUser.roles === parseInt(process.env.REACT_APP_WEBMASTER))
                    ? adminImg
                    : props.currentUser.roles === parseInt(process.env.REACT_APP_OWNER)
                    ? ownerImg
                    : avatar
                }
                alt="user role"
                className="img-fluid"
              />
            </Col>
          </Row>
        </div>
        <CardBody className="pt-0">
          <Row>
            <Col sm="4">
              <div className="avatar-md profile-user-wid mb-4">
                <img src={props.currentUser.avatar ? props.currentUser.avatar : avatar} alt="user_avatar" className="img-thumbnail rounded-circle" />
              </div>
              <h5 className="font-size-15 text-truncate">
                {props.currentUser.lastname} {props.currentUser.firstname}
              </h5>
              <p className="text-muted mb-0 text-truncate">{role(props.currentUser.roles)}</p>
            </Col>

            <Col sm="8">
              <div className="pt-4">
                <Row>
                  <Col xs="6">
                    <h5 className="font-size-15">125</h5>
                    <p className="text-muted mb-0">Projects</p>
                  </Col>
                  <Col xs="6">
                    <h5 className="font-size-15">$1245</h5>
                    <p className="text-muted mb-0">Revenue</p>
                  </Col>
                </Row>
                <div className="mt-4">
                  <Link to="/profile" className="btn btn-primary  btn-sm">
                    View Profile <i className="mdi mdi-arrow-right ms-1"></i>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
}
export default WelcomeComp
