import React,{useState, useEffect, useRef} from "react"
import { Container, Row, Col } from "reactstrap"
import IdleTimeContainer from "components/IdleTimeContainer";

const Footer = () =>
{

  const [timers, setTimers] = useState(0);
  const time = new Date(timers * 1000).toISOString().slice(12, 19);

  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col md={4}>{new Date().getFullYear()} © Deous.</Col>
            <Col md={4} className="text-center">
              {timers<60 &&  timers>0 ?  <p>session expires in: {time}</p> : "" }
            </Col>
            <Col md={4}>
              <div className="text-sm-end d-none d-sm-block">
                Developed by <a href="https://www.deous.gr" target="_blank" rel="noreferrer">DEOUS</a>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
      <IdleTimeContainer timers={timers} setTimers={setTimers}/>
    </React.Fragment>
  )
}

export default Footer
