import React, {useState} from "react";
import {Modal, ModalHeader, ModalBody, ModalFooter} from "reactstrap";
import {useIdleTimer} from "react-idle-timer";
import {useHistory} from "react-router-dom";

const IdleTimeContainer = ({timers, setTimers}) => {
  const currentUser = JSON.parse(localStorage.getItem("user"));
  if (currentUser) {
    const history = useHistory();
    const [idleModal, setIdleModal] = useState(false);
    //const [timers, setTimers] = useState(0);

    const onPrompt = () => {
      // Fire a Modal Prompt
      setIdleModal(true);
    };

    const onIdle = () => {
      logOut();
    };

    const onActive = (event) => {};
    const onAction = (event) => {};

    const {start, reset, activate, pause, resume, isIdle, isPrompted, isLeader, getTabId, getRemainingTime, getElapsedTime, getLastIdleTime, getLastActiveTime, getTotalIdleTime, getTotalActiveTime, getTotalElapsedTime} =
      useIdleTimer({
        onPrompt,
        onIdle,
        onActive,
        onAction,
        timeout: 1000 * 60 * parseInt(currentUser.ttl) - 60000,
        promptTimeout: 1000 * 60,
        events: ["mousemove", "keydown", "wheel", "DOMMouseScroll", "mousewheel", "mousedown", "touchstart", "touchmove", "MSPointerDown", "MSPointerMove", "visibilitychange"],
        immediateEvents: [],
        debounce: 1000,
        throttle: 0,
        eventsThrottle: 1000,
        element: document,
        startOnMount: true,
        startManually: false,
        stopOnIdle: false,
        crossTab: true,
        name: "idle-timer",
        syncTimers: 1000,
        leaderElection: false,
      });

    const logOut = () => {
      try {
        setIdleModal(false);
        localStorage.removeItem("authUser");
        localStorage.removeItem("user");
        history.push("/login");
      } catch (error) {
        console.log(error.message);
      }
    };

    setInterval(() => {
      setTimers(Math.round(getRemainingTime() / 1000));
    }, 1000);

    const extendSession = () => {
      reset();
      setIdleModal(false);
    };
    return (
      <Modal
        isOpen={idleModal}
        //toggle={() => setIdleModal(false)}
      >
        <ModalHeader
        //   toggle={() => setIdleModal(false)}
        >
          Session expire warning
        </ModalHeader>
        <ModalBody>Your session will expire in {timers} seconds. Do you want to stay logged in ?</ModalBody>
        <ModalFooter>
          <button className="btn btn-info" onClick={() => logOut()}>
            Logout
          </button>
          <button className="btn btn-success" onClick={() => extendSession()}>
            Continue
          </button>
        </ModalFooter>
      </Modal>
    );
  }
};

export default IdleTimeContainer;
