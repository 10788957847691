import PropTypes from "prop-types";
import React, {useState} from "react";
import { Row, Col, Alert, Card, CardBody, Container, FormFeedback, Input, Label, Form } from "reactstrap";
import {getAxiosDefaultConfig, getId} from "../../utils";
import axios from "axios";
import {API_URL} from "../../config";
import { withRouter, Link } from "react-router-dom";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// action
import { userResetPassword } from "../../store/actions";

// import images
import logo from "../../assets/images/logo.svg";

const ResetPasswordPage = (props) => {
    const [hasResponse, setHasResponse] = useState({});
  //meta title
  document.title="Reset Password | Admin Zakynthos Luxury Villas";

  const submitForm = async (values) => {

    const axiosConfig = getAxiosDefaultConfig();


      const url = API_URL + "/reset";
      try {
        await axios
          .post(url, values, getAxiosDefaultConfig())
          .then((res) => {
            setHasResponse(res.data);
          })
          .catch((error) => {
            console.log(error);
            setHasResponse(error.response.data);
          });
      } catch (error) {
        console.log(error.response.data.error);
        setHasResponse(error.response.data);
      }

  };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Enter a valid email").required("Please Enter Your Email"),
    }),
    onSubmit: (values) =>
    {
       submitForm(values);
    }
  });

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary">
                  <Row>
                    <Col xs={6}>
                      <div className="text-black p-4">
                        <p>Enter your email to reset your password.</p>
                      </div>
                    </Col>
                    <Col className="col-6 align-self-end">
 
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  {/* <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img src={logo} alt="" className="rounded-circle" height="34" />
                        </span>
                      </div>
                    </Link>
                  </div> */}
                  <div className="p-2">
                    {hasResponse.status && hasResponse.status !== 200 ? (
                      <Alert color="danger" style={{marginTop: "13px"}}>
                        {hasResponse.message}
                      </Alert>
                    ) : null}
                    {hasResponse && hasResponse.status === 200 ? (
                      <Alert color="success" style={{marginTop: "13px"}}>
                        {hasResponse.message}
                      </Alert>
                    ) : null}

                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}>
                      <div className="form-floating mb-3">
                        <input
                          className={validation.touched.email && validation.errors.email ? "form-control is-invalid" : "form-control"}
                          autoComplete="off"
                          name="email"
                          id="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          type="email"
                        />
                        <label htmlFor="email">{validation.touched.email && validation.errors.email ? <span style={{color: "red"}}>{validation.errors.email}</span> : "Enter your email"}</label>
                      </div>
                      <div className="mt-3 d-grid">
                        <button className="btn btn-primary btn-block " type="submit">
                          Reset
                        </button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Go back to{" "}
                  <Link to="/login" className="font-weight-medium text-primary">
                    Login
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()}{" "}
                  <Link to="https://www.deous.gr" target={"_blank"}>
                    {" "}
                    Deous
                  </Link>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

ResetPasswordPage.propTypes = {
  history: PropTypes.object,
};

const mapStatetoProps = state => {
  const { forgetError, forgetSuccessMsg } = state.ResetPassword;
  return { forgetError, forgetSuccessMsg };
};

export default withRouter(ResetPasswordPage);
