import PropTypes from "prop-types";
import React, {useState} from "react";
import {Row, Col, Alert, Card, CardBody, Container, FormFeedback, Input, Label, Form} from "reactstrap";
import {getAxiosDefaultConfig, getId} from "../../utils";
import axios from "axios";
import {API_URL} from "../../config";
import {withRouter, useParams, Link} from "react-router-dom";

// Formik Validation
import * as Yup from "yup";
import {useFormik} from "formik";

// action
import {userResetPassword} from "../../store/actions";

// import images

import logo from "../../assets/images/logo.svg";
import { useEffect } from "react";

const RstPasswordPage = (props) =>
{
      let {uuid} = useParams();
  const [passwordShown, setPasswordShown] = useState(false);
  const [hasResponse, setHasResponse] = useState({});
  //meta title
  document.title = "Reset Password | Admin Zakynthos Luxury Villas";

    
    
  const submitForm = async (values) => {
    const axiosConfig = getAxiosDefaultConfig();

    const url = API_URL + "/reset/"+uuid;
    try {
      await axios
        .put(url, values, getAxiosDefaultConfig())
        .then((res) => {
            setHasResponse(res.data);
        })
        .catch((error) => {
          console.log(error);
          setHasResponse(error.response.data);
        });
    } catch (error) {
      console.log(error.response.data.error);
      setHasResponse(error.response.data);
    }
  };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      password: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .nullable()
        .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/, "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special character")
        .required("Password is required"),
      passwordConfirm: Yup.string()
        .nullable()
        .oneOf([Yup.ref("password"), null], "Passwords do not match!")
        .required("Password confirmation is required"),
    }),
    onSubmit: (values) => {
      submitForm(values);
    },
  });
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };
   
    
  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-softbg-soft-primary">
                  <Row>
                    <Col xs={7}>
                      <div className="text-black p-4">
                        <p>&nbsp;</p>
                        <p>Enter your password.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img src={logo} alt="" className="rounded-circle" height="34" />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    {hasResponse.status && hasResponse.status !== 200 ? (
                      <Alert color="danger" style={{marginTop: "13px"}}>
                        {hasResponse.message}
                      </Alert>
                    ) : null}
                    {hasResponse && hasResponse.status === 200 ? (
                      <Alert color="success" style={{marginTop: "13px"}}>
                        {hasResponse.message}
                      </Alert>
                    ) : null}

                    <Form
                      className="needs-validation"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}>
                      <div className="form-floating mb-3 position-relative">
                        <input
                          className={validation.touched.password && validation.errors.password ? "form-control is-invalid" : "form-control"}
                          autoComplete="off"
                          name="password"
                          id="password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.password || ""}
                          type={passwordShown ? "text" : "password"}
                        />
                        <span className="viewpass" style={{right: validation.errors.password ? "33px" : "11px"}} onClick={togglePassword}>
                          {passwordShown ? <i className="text-muted far fa-eye" /> : <i className="text-muted far fa-eye-slash" />}
                        </span>
                        <label htmlFor="password">Enter new password</label>
                        {validation.errors.password ? <div className="invalid-tooltip">{validation.errors.password}</div> : null}
                      </div>
                      <div className="form-floating mb-3 position-relative">
                        <input
                          className={validation.touched.passwordConfirm && validation.errors.passwordConfirm ? "form-control is-invalid" : "form-control"}
                          autoComplete="off"
                          name="passwordConfirm"
                          id="passwordConfirm"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.passwordConfirm || ""}
                          type={passwordShown ? "text" : "password"}
                        />
                        <span className="viewpass" style={{right: validation.errors.passwordConfirm ? "33px" : "11px"}} onClick={togglePassword}>
                          {passwordShown ? <i className="text-muted far fa-eye" /> : <i className="text-muted far fa-eye-slash" />}
                        </span>
                        <label htmlFor="passwordConfirm">Confirm new password</label>
                        {validation.errors.passwordConfirm ? <div className="invalid-tooltip">{validation.errors.passwordConfirm}</div> : null}
                      </div>
                      <Row className="mb-3">
                        <Col className="text-end">
                          <button className="btn btn-primary w-md " type="submit">
                            Reset
                          </button>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Go back to{" "}
                  <Link to="/login" className="font-weight-medium text-primary">
                    Login
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()}{" "}
                  <Link to="https://www.deous.gr" target={"_blank"}>
                    {" "}
                    Deous
                  </Link>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

RstPasswordPage.propTypes = {
  history: PropTypes.object,
};

const mapStatetoProps = (state) => {
  const {forgetError, forgetSuccessMsg} = state.ResetPassword;
  return {forgetError, forgetSuccessMsg};
};

export default withRouter(RstPasswordPage);
