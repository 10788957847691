import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { FORGET_PASSWORD } from "./actionTypes"
import { userResetPasswordSuccess, userResetPasswordError } from "./actions"



//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* forgetUser({ payload: { user, history } }) {
  try {
      const response = yield call(postJwtForgetPwd, "/reset", {
        email: user.email,
      })
      if (response) {
        yield put(
          userResetPasswordSuccess(
            "Reset link are sended to your mailbox, check there first"
          )
        )
      }

  } catch (error) {
    yield put(userResetPasswordError(error))
  }
}

export function* watchUserPasswordForget() {
  yield takeEvery(FORGET_PASSWORD, forgetUser)
}

function* ResetPasswordSaga() {
  yield all([fork(watchUserPasswordForget)])
}

export default ResetPasswordSaga
