import React, {useState, useEffect} from "react";
import {Container, Row, Col, Card, Alert, CardBody, Button, CardText, Label, Input, Form, Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap";
import Select from "react-select";
// Formik Validation
import * as Yup from "yup";
import {useFormik} from "formik";
import LanguageDropdown from "../../components/CommonForBoth/TopbarDropdown/LanguageDropdown";
import {withRouter} from "react-router-dom";
import {getAxiosDefaultConfig, getId} from "../../utils";
import axios from "axios";
import {API_URL} from "../../config";
import classnames from "classnames";
//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";
import Dropzone from "react-dropzone";
import avatar from "../../assets/images/users/avatar.png";
import {countries} from "common/languages";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
// actions
import {editProfile, resetProfileFlag} from "../../store/actions";

import {Link} from "react-router-dom";
import {number} from "prop-types";
import moment from "moment";
import {locale} from "moment";
moment.locale("el");

const UserProfile = () => {
  const [error, setError] = useState(true);
  //meta title
  document.title = "Profile | Admin Zakynthos Luxury Villas";
  const [hasResponse, setHasResponse] = useState();
  const [currentUser, setCurrentUser] = useState({
    firstname: null,
    lastname: null,
    username: null,
    email: null,
    id: null,
    password: null,
    avatar: null,
    active: null,
    roles: null,
    created: null,
    lang: null,
    address: null,
    pc: null,
    city: null,
    region: null,
    country: null,
    phone: null,
    contactasbilling: null,
    billing_name: null,
    billing_address: null,
    billing_pc: null,
    billing_city: null,
    billing_region: null,
    billing_country: null,
    billing_phone: null,
    billing_vat: null,
    billing_doy: null,
    ttl: null,
    securityPhrase: null,

  });
  const [billing, setBilling] = useState({
    billing_address: null,
    billing_pc: null,
    billing_city: null,
    billing_region: null,
    billing_country: null,
    billing_phone: null,
  });
  const [selectedFiles, setselectedFiles] = useState([]);
  const [customActiveTab, setcustomActiveTab] = useState("1");
  const [passwordShown, setPasswordShown] = useState(false);

  const getUserInfo = async () => {
    const url = API_URL + "/users/" + getId();
    await axios
      .get(url, getAxiosDefaultConfig())
      .then((response) => {
        setCurrentUser(response.data);
        localStorage.setItem("user", JSON.stringify(response.data));
      })
      .catch((error) => {
        console.log(error.response.data.error);
      });
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  function handleAcceptedFiles(files) {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      }),
    );
    setselectedFiles(files);
  }

  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const submitForm = async (values) => {
    setHasResponse(null);
    const url = API_URL + "/users/" + getId();
    const axiosConfig = getAxiosDefaultConfig();
    const formData = new FormData();
    const data = {...values, ...selectedFiles[0]};
    formData.append("file", selectedFiles[0]);

    formData.append("data", JSON.stringify(data));
    try {
      await axios
        .put(url, formData, getAxiosDefaultConfig())
        .then((res) => {
          setHasResponse(res.data.message);
          getUserInfo();
        })
        .catch((error) => {
          console.log(error);
          setHasResponse(error.response.data.message);
        });
    } catch (error) {
      console.log(error.response.data.error);
      setHasResponse(error.response.data.message);
    }
  };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      firstname: currentUser.firstname || "",
      lastname: currentUser.lastname || "",
      username: currentUser.username || "",
      email: currentUser.email || null,
      id: currentUser.id || "",
      password: null,
      avatar: currentUser.avatar || "",
      active: (currentUser.active ? 1 : 0) || "",
      roles: currentUser.roles || "",
      address: currentUser.address || "",
      pc: currentUser.pc || "",
      city: currentUser.city || "",
      region: currentUser.region || "",
      country: currentUser.country || "",
      phone: currentUser.phone || "",
      contactasbilling: currentUser.email !== undefined ? currentUser.contactasbilling : true,
      billing_name: currentUser.billing_name || "",
      billing_address: currentUser.billing_address || billing.billing_address || "",
      billing_pc: currentUser.billing_pc || billing.billing_pc || "",
      billing_city: currentUser.billing_city || billing.billing_city || "",
      billing_region: currentUser.billing_region || billing.billing_region || "",
      billing_country: currentUser.billing_country || billing.billing_country || "",
      billing_phone: currentUser.billing_phone || billing.billing_phone || "",
      billing_vat: currentUser.billing_vat || "",
      billing_doy: currentUser.billing_doy || "",
      securityPhrase: currentUser.securityPhrase || "",
      ttl: currentUser.ttl || "",
    },
    validationSchema: Yup.object({
      username: Yup.string().nullable().required("Username is required").max(100, "Up to 100 characters"),
      password:
        currentUser.email === undefined
          ? Yup.string()
              .nullable()
              .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/, "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special character")
              .required("Password is required")
          : Yup.string().nullable(),
      passwordconfirm:
        currentUser.email === undefined
          ? Yup.string()
              .nullable()
              .oneOf([Yup.ref("password"), null], "Passwords must match")
              .required("Password confirm is required")
          : Yup.string().nullable(),
      firstname: Yup.string().nullable().max(100, "Up to 100 characters"),
      lastname: Yup.string().nullable().max(100, "Up to 100 characters"),
      active: Yup.boolean(),
      roles: Yup.number(),
      id: Yup.string(),
      email: Yup.string().nullable().email("Must be a valid Email").required("Please Enter user email").max(100, "Up to 100 characters"),
      avatar: Yup.string().nullable(),
      address: Yup.string().nullable().max(50, "Up to 50 characters").max(50, "Up to 50 characters"),
      pc: Yup.number("Must be a number").nullable().max(99999, "Up to 5 digits"),
      city: Yup.string().nullable().max(50, "Up to 50 characters"),
      region: Yup.string().nullable().max(50, "Up to 50 characters"),
      country: Yup.number().nullable(),
      phone: Yup.string().nullable().max(20, "Up to 20 characters"),
      contactasbilling: Yup.boolean(),
      billing_name: Yup.string().nullable().max(100, "Up to 100 characters"),
      billing_address: Yup.string().nullable().max(50, "Up to 50 characters"),
      billing_pc: Yup.number("Must be a number").nullable().max(99999, "Up to 5 digits"),
      billing_city: Yup.string().nullable().max(50, "Up to 50 characters"),
      billing_region: Yup.string().nullable().max(50, "Up to 50 characters"),
      billing_country: Yup.number().nullable(),
      billing_vat: Yup.string().nullable().max(15, "Up to 15 characters"),
      billing_doy: Yup.string().nullable().max(50, "Up to 50 characters"),
      securityPhrase: Yup.string().nullable().max(100, "Up to 100 characters"),
      ttl: Yup.number("Must be a number").nullable().min(5, "Min time is 5 minutes").max(1440, "Max time is 1 day"),
    }),
    onSubmit: (values) => {
      //submitForm(values);
      console.log(values)
    },
  });

  const optionGroup = [
    {label: "Webmaster", value: parseInt(process.env.REACT_APP_WEBMASTER)},
    {label: "Admin", value: parseInt(process.env.REACT_APP_ADMIN)},
    {label: "Owner", value: parseInt(process.env.REACT_APP_OWNER)},
    {label: "Guest", value: parseInt(process.env.REACT_APP_GUEST)},
  ];

  const optionGroup1 = [
    {label: "Active", value: 1},
    {label: "Disabled", value: 0},
  ];

  useEffect(() => {
    toast(hasResponse);
  }, [hasResponse]);

  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          {/* <Breadcrumb title="Event Admin" breadcrumbItem="Profile" /> */}

          <Row>
            <Col lg="8">
              {/* {error && error ? <Alert color="danger">{error}</Alert> : null}
              {success ? <Alert color="success">{success}</Alert> : null} */}

              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="ms-3 text-center">
                      <img src={currentUser.avatar ? currentUser.avatar : avatar} alt="user image" className="avatar-md rounded-circle img-thumbnail" />
                      {/* <p className="mb-0">Id: {currentUser.id}</p> */}
                    </div>
                    <div className="flex-grow-1 align-self-center">
                      <div className="text-muted">
                        <h4 className="mb-1">{currentUser.username}</h4>
                        <p className="mb-0">Member since {moment(currentUser.created).format("dddd Do MMMM YYYY")}</p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Col lg="8">
            <Card>
              <CardBody>
                {currentUser.email !== undefined && (
                  <Nav tabs className="nav-tabs-custom nav-justified">
                    <NavItem>
                      <NavLink
                        style={{cursor: "pointer"}}
                        className={classnames({
                          active: customActiveTab === "1",
                        })}
                        onClick={() => {
                          toggleCustom("1");
                        }}>
                        <span className="d-block d-sm-none">
                          <i className="fas fa-home"></i>
                        </span>
                        <span className="d-none d-sm-block">User Information</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{cursor: "pointer"}}
                        className={classnames({
                          active: customActiveTab === "2",
                        })}
                        onClick={() => {
                          toggleCustom("2");
                        }}>
                        <span className="d-block d-sm-none">
                          <i className="far fa-user"></i>
                        </span>
                        <span className="d-none d-sm-block">Contact Information</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{cursor: "pointer"}}
                        className={classnames({
                          active: customActiveTab === "3",
                        })}
                        onClick={() => {
                          toggleCustom("3");
                        }}>
                        <span className="d-block d-sm-none">
                          <i className="far fa-envelope"></i>
                        </span>
                        <span className="d-none d-sm-block">Billing Information</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{cursor: "pointer"}}
                        className={classnames({
                          active: customActiveTab === "4",
                        })}
                        onClick={() => {
                          toggleCustom("4");
                        }}>
                        <span className="d-block d-sm-none">
                          <i className="far fa-envelope"></i>
                        </span>
                        <span className="d-none d-sm-block">Access & Security</span>
                      </NavLink>
                    </NavItem>
                  </Nav>
                )}
                <Form
                  className="needs-validation"
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}>
                  <TabContent activeTab={customActiveTab} className="pt-5 text-muted">
                    <TabPane tabId="1">
                      <Row>
                        <Col sm="12">
                          <CardText className="mb-0">
                            <Row>
                              <Col md={currentUser.email === undefined ? "12" : "6"}>
                                <div className="form-floating mb-1">
                                  <input
                                    className={validation.touched.email && validation.errors.email ? "form-control is-invalid" : "form-control"}
                                    autoComplete="off"
                                    name="email"
                                    id="email"
                                    value={currentUser.email}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    type="email"
                                    disabled={currentUser.email ? true : false}
                                  />
                                  <label htmlFor="email">{validation.touched.email && validation.errors.email ? <span style={{color: "red"}}>{validation.errors.email}</span> : "Enter user email"}</label>
                                </div>
                              </Col>
                              <Col md={currentUser.email === undefined ? "12" : "6"}>
                                <div className="form-floating mb-1 position-relative">
                                  <input
                                    className={validation.errors.password ? "form-control is-invalid" : "form-control"}
                                    autoComplete="off"
                                    name="password"
                                    id="password"
                                    placeholder="Enter password"
                                    value={currentUser.password}
                                    onChange={validation.handleChange}
                                    type={passwordShown ? "text" : "password"}
                                  />
                                  <span className="viewpass" style={{right: validation.errors.password ? "33px" : "11px"}} onClick={togglePassword}>
                                    {passwordShown ? <i className="text-muted far fa-eye" /> : <i className="text-muted far fa-eye-slash" />}
                                  </span>
                                  <label htmlFor="password">Enter user password</label>
                                  {validation.errors.password ? <div className="invalid-tooltip">{validation.errors.password}</div> : null}
                                </div>
                              </Col>
                              {currentUser.email === undefined && (
                                <Col md="12">
                                  <div className="form-floating mb-1 position-relative">
                                    <input
                                      className={validation.errors.passwordconfirm ? "form-control is-invalid" : "form-control"}
                                      autoComplete="off"
                                      name="passwordconfirm"
                                      id="passwordconfirm"
                                      placeholder="Confirm password"
                                      onChange={validation.handleChange}
                                      type={passwordShown ? "text" : "password"}
                                    />
                                    <span className="viewpass" style={{right: validation.errors.passwordconfirm ? "33px" : "11px"}} onClick={togglePassword}>
                                      {passwordShown ? <i className="text-muted far fa-eye" /> : <i className="text-muted far fa-eye-slash" />}
                                    </span>
                                    <label htmlFor="passwordconfirm">Confirm password</label>
                                    {validation.errors.passwordconfirm ? <div className="invalid-tooltip">{validation.errors.passwordconfirm}</div> : null}
                                  </div>
                                </Col>
                              )}
                            </Row>
                            <Row>
                              <Col md="12">
                                <div className="form-floating mb-1">
                                  <input
                                    autoComplete="off"
                                    name="username"
                                    id="username"
                                    placeholder="Enter username"
                                    className={validation.errors.username ? "form-control is-invalid" : "form-control"}
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.username || ""}
                                    // invalid={validation.touched.username && validation.errors.username ? true : false}
                                  />
                                  <label htmlFor="username">{validation.errors.username ? <span style={{color: "red"}}>{validation.errors.username}</span> : "Enter username "}</label>
                                </div>
                                <Input name="id" value={currentUser.id} type="hidden" />
                              </Col>
                            </Row>
                            <Row>
                              <Col md="6">
                                <div className="form-floating mb-1">
                                  <input
                                    name="firstname"
                                    id="firstname"
                                    placeholder="Enter first name"
                                    value={validation.values.firstname || ""}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    className={validation.errors.firstname ? "form-control is-invalid" : "form-control"}
                                    type="text"
                                  />
                                  <label htmlFor="firstname">{validation.errors.firstname ? <span style={{color: "red"}}>{validation.errors.firstname}</span> : "Enter first name"}</label>
                                </div>
                              </Col>
                              <Col md="6">
                                <div className="form-floating mb-1">
                                  <input
                                    name="lastname"
                                    id="lastname"
                                    placeholder="Enter lastname"
                                    value={validation.values.lastname || ""}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    className={validation.errors.lastname ? "form-control is-invalid" : "form-control"}
                                    type="text"
                                  />
                                  <label htmlFor="lastname">{validation.errors.lastname ? <span style={{color: "red"}}>{validation.errors.lastname}</span> : "Enter last name"}</label>
                                </div>
                              </Col>
                            </Row>

                            {currentUser.email && (
                              <div className="form-floating mb-1">
                                <Dropzone
                                  maxSize={2000000}
                                  onDrop={(acceptedFiles) => {
                                    handleAcceptedFiles(acceptedFiles);
                                  }}>
                                  {({getRootProps, getInputProps}) => (
                                    <div className="dropzone" style={{minHeight: "180px"}}>
                                      <div className="dz-message needsclick mt-1" {...getRootProps()}>
                                        <input {...getInputProps()} name="avatar" id="avatar" />
                                        <div className="mb-1">
                                          <i className="display-4 text-muted bx bxs-cloud-upload" />
                                        </div>
                                        <h5>Drop files here or click to upload.</h5>
                                        <h6>Max file size 2MB.</h6>
                                      </div>
                                    </div>
                                  )}
                                </Dropzone>
                                <div className="dropzone-previews mt-3" id="file-previews">
                                  {selectedFiles.map((f, i) => {
                                    return (
                                      <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete" key={i + "-file"}>
                                        <div className="p-2">
                                          <Row className="align-items-center">
                                            <Col className="col-auto">
                                              <img data-dz-thumbnail="" height="80" className="avatar-sm rounded bg-light" alt={f.name} src={f.preview} />
                                            </Col>
                                            <Col className="col-auto">
                                              <Link to="#" className="text-muted font-weight-bold">
                                                {f.name}
                                              </Link>
                                              <p className="mb-0">
                                                <strong>{f.formattedSize}</strong>
                                              </p>
                                            </Col>
                                            <Col className="text-end my-2">
                                              <button
                                                type="button"
                                                className="btn btn-danger w-xs"
                                                onClick={() => {
                                                  setselectedFiles([]);
                                                }}>
                                                <i className="mdi mdi-trash-can font-size-15"></i>{" "}
                                              </button>
                                            </Col>
                                          </Row>
                                        </div>
                                      </Card>
                                    );
                                  })}
                                </div>
                                <label htmlFor="avatar">Upload avatar</label>
                              </div>
                            )}
                          </CardText>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="2">
                      <Row>
                        <Col sm="12">
                          <CardText className="mb-0">
                            <Row>
                              <Col md="8">
                                <div className="form-floating mb-1">
                                  <input
                                    autoComplete="off"
                                    name="address"
                                    id="address"
                                    placeholder="Enter address"
                                    className={validation.errors.address ? "form-control is-invalid" : "form-control"}
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.address || ""}
                                  />
                                  <label htmlFor="address">{validation.errors.address ? <span style={{color: "red"}}>{validation.errors.address}</span> : "Enter user address"}</label>
                                </div>
                              </Col>
                              <Col md="4">
                                <div className="form-floating mb-1">
                                  <input
                                    autoComplete="off"
                                    name="pc"
                                    id="pc"
                                    placeholder="Enter post code"
                                    className={validation.errors.pc ? "form-control is-invalid" : "form-control"}
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.pc || ""}
                                    //invalid={validation.touched.pc && validation.errors.pc ? true : false}
                                  />
                                  <label htmlFor="pc">{validation.errors.pc ? <span style={{color: "red"}}>{validation.errors.pc}</span> : "Enter post code"}</label>
                                </div>
                                {/*  */}
                              </Col>
                            </Row>
                            <Row>
                              <Col md="6">
                                <div className="form-floating mb-1">
                                  <input
                                    autoComplete="off"
                                    name="city"
                                    id="city"
                                    placeholder="Enter city"
                                    className={validation.errors.city ? "form-control is-invalid" : "form-control"}
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.city || ""}
                                  />
                                  <label htmlFor="city">{validation.errors.city ? <span style={{color: "red"}}>{validation.errors.city}</span> : "Enter city"}</label>
                                </div>
                              </Col>
                              <Col md="6">
                                <div className="form-floating mb-1">
                                  <input
                                    autoComplete="off"
                                    name="region"
                                    id="region"
                                    placeholder="Enter region"
                                    className={validation.errors.region ? "form-control is-invalid" : "form-control"}
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.region || ""}
                                  />
                                  <label htmlFor="region">{validation.errors.region ? <span style={{color: "red"}}>{validation.errors.region}</span> : "Enter region"}</label>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col md="6">
                                <div className="form-floating mb-1">
                                  <input
                                    autoComplete="off"
                                    name="phone"
                                    id="phone"
                                    placeholder="Enter phone number"
                                    className={validation.errors.phone ? "form-control is-invalid" : "form-control"}
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.phone || ""}
                                  />
                                  <label htmlFor="phone">{validation.errors.phone ? <span style={{color: "red"}}>{validation.errors.phone}</span> : "Enter phone number"}</label>
                                </div>
                              </Col>
                              <Col md="6">
                                <div className="form-floating mb-1">
                                  <select
                                    className="form-select"
                                    styles={{zIndex: "2"}}
                                    name="country"
                                    id="country"
                                    placeholder="Enter country"
                                    defaultValue={validation.values.active ? 1 : 0}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}>
                                    <option value="" disabled>
                                      Select...
                                    </option>
                                    {countries.map((country) => {
                                      return (
                                        <option key={country.id} value={country.id} selected={validation.values.country === country.id ? true : false}>
                                          {country.label}
                                        </option>
                                      );
                                    })}
                                  </select>
                                  <label htmlFor="active">Select country</label>
                                </div>
                              </Col>
                            </Row>
                          </CardText>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="3">
                      <Row>
                        <Col sm="12">
                          <CardText className="mb-0">
                            <Row>
                              <Col md="12">
                                <div className="form-floating mb-1">
                                  <div className="form-check form-switch mb-3">
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      id="contactasbilling"
                                      name="contactasbilling"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      defaultValue={validation.values.contactasbilling}
                                      defaultChecked={validation.values.contactasbilling}
                                    />
                                    <label className="form-check-label" htmlFor="contactasbilling">
                                      Use contact address as billing address
                                    </label>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col md="12">
                                <div className="form-floating mb-1">
                                  <input
                                    autoComplete="off"
                                    name="billing_name"
                                    id="billing_name"
                                    placeholder="Enter name"
                                    className={validation.errors.billing_name ? "form-control is-invalid" : "form-control"}
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.billing_name || ""}
                                  />
                                  <label htmlFor="billing_name">{validation.errors.billing_name ? <span style={{color: "red"}}>{validation.errors.billing_name}</span> : "Business name"}</label>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col md="8">
                                <div className="form-floating mb-1">
                                  <input
                                    disabled={validation.values.contactasbilling}
                                    autoComplete="off"
                                    name="billing_address"
                                    id="billing_address"
                                    placeholder="Enter address"
                                    className={validation.errors.billing_address ? "form-control is-invalid" : "form-control"}
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.contactasbilling ? validation.values.address : validation.values.billing_address || ""}
                                  />
                                  <label htmlFor="billing_address">{validation.errors.billing_address ? <span style={{color: "red"}}>{validation.errors.billing_address}</span> : "Address"}</label>
                                </div>
                              </Col>
                              <Col md="4">
                                <div className="form-floating mb-1">
                                  <input
                                    disabled={validation.values.contactasbilling}
                                    autoComplete="off"
                                    name="billing_pc"
                                    id="billing_pc"
                                    placeholder="Enter post code"
                                    className={validation.errors.billing_pc ? "form-control is-invalid" : "form-control"}
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.contactasbilling ? validation.values.pc : validation.values.billing_pc || ""}
                                  />
                                  <label htmlFor="billing_pc">{validation.errors.billing_pc ? <span style={{color: "red"}}>{validation.errors.billing_pc}</span> : "Post code"}</label>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col md="6">
                                <div className="form-floating mb-1">
                                  <input
                                    disabled={validation.values.contactasbilling}
                                    autoComplete="off"
                                    name="billing_city"
                                    id="billing_city"
                                    placeholder="Enter city"
                                    className={validation.errors.billing_city ? "form-control is-invalid" : "form-control"}
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.contactasbilling ? validation.values.city : validation.values.billing_city || ""}
                                  />
                                  <label htmlFor="billing_city">{validation.errors.billing_city ? <span style={{color: "red"}}>{validation.errors.billing_city}</span> : "City"}</label>
                                </div>
                              </Col>
                              <Col md="6">
                                <div className="form-floating mb-1">
                                  <input
                                    disabled={validation.values.contactasbilling}
                                    autoComplete="off"
                                    name="billing_region"
                                    id="billing_region"
                                    placeholder="Enter region"
                                    className={validation.errors.billing_region ? "form-control is-invalid" : "form-control"}
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.contactasbilling ? validation.values.region : validation.values.billing_region || ""}
                                  />
                                  <label htmlFor="billing_region">{validation.errors.billing_region ? <span style={{color: "red"}}>{validation.errors.billing_region}</span> : "Region"}</label>
                                </div>
                              </Col>
                              <Col md="6">
                                <div className="form-floating mb-1">
                                  <select
                                    disabled={validation.values.contactasbilling}
                                    autoComplete="off"
                                    name="billing_country"
                                    id="billing_country"
                                    className="form-select"
                                    styles={{zIndex: "2"}}
                                    defaultValue={validation.values.contactasbilling ? validation.values.country : validation.values.billing_country || ""}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}>
                                    <option value="" disabled>
                                      Select...
                                    </option>
                                    {countries.map((country) => {
                                      return (
                                        <option key={country.id} value={country.id} selected={validation.values.country === country.id ? true : false}>
                                          {country.label}
                                        </option>
                                      );
                                    })}
                                  </select>
                                  <label htmlFor="billing_country">{validation.errors.billing_country ? <span style={{color: "red"}}>{validation.errors.billing_country}</span> : "Country"}</label>
                                </div>
                              </Col>
                              <Col md="6">
                                <div className="form-floating mb-1">
                                  <input
                                    disabled={validation.values.contactasbilling}
                                    autoComplete="off"
                                    name="billing_phone"
                                    id="billing_phone"
                                    placeholder="Enter phone number"
                                    className={validation.errors.billing_phone ? "form-control is-invalid" : "form-control"}
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.contactasbilling ? validation.values.phone : validation.values.billing_phone || ""}
                                  />
                                  <label htmlFor="billing_phone">{validation.errors.billing_phone ? <span style={{color: "red"}}>{validation.errors.billing_phone}</span> : "Phone number"}</label>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col md="6">
                                <div className="form-floating mb-1">
                                  <input
                                    autoComplete="off"
                                    name="billing_vat"
                                    id="billing_vat"
                                    placeholder="Enter VAT number"
                                    className={validation.errors.billing_vat ? "form-control is-invalid" : "form-control"}
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.billing_vat || ""}
                                  />
                                  <label htmlFor="billing_vat">{validation.errors.billing_vat ? <span style={{color: "red"}}>{validation.errors.billing_vat}</span> : "VAT number"}</label>
                                </div>
                              </Col>
                              <Col md="6">
                                <div className="form-floating mb-1">
                                  <input
                                    autoComplete="off"
                                    name="billing_doy"
                                    id="billing_doy"
                                    placeholder="Enter TAX office"
                                    className={validation.errors.billing_doy ? "form-control is-invalid" : "form-control"}
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.billing_doy || ""}
                                  />
                                  <label htmlFor="billing_doy">{validation.errors.billing_doy ? <span style={{color: "red"}}>{validation.errors.billing_doy}</span> : "TAX Office"}</label>
                                </div>
                              </Col>
                            </Row>
                          </CardText>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="4">
                      <Row>
                        <Col sm="12">
                          <CardText className="mb-0">
                            <Row>
                              <Col md="12">
                                <div className="form-floating mb-1">
                                  <input
                                    name="ttl"
                                    id="ttl"
                                    value={validation.values.ttl || ""}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    className={validation.errors.ttl ? "form-control is-invalid" : "form-control"}
                                    placeholder="Enter duration to logout"
                                    type="number"
                                    min="0"
                                  />
                                  <label htmlFor="ttl">{validation.errors.ttl ? <span style={{color: "red"}}>{validation.errors.ttl}</span> : "Log out after (x) minutes of inactivity*"}</label>
                                  <p style={{fontSize: "11px"}}>* The user will automatically log out after a specified duration (in minutes) of been inactive. Default value is 60 minutes.</p>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col md="12">
                                <div className="form-floating mb-1">
                                  <input
                                    name="securityPhrase"
                                    id="securityPhrase"
                                    value={validation.values.securityPhrase || ""}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    placeholder="Enter security phrase"
                                    className={validation.errors.securityPhrase ? "form-control is-invalid" : "form-control"}
                                    type={passwordShown ? "text" : "password"}
                                  />
                                  <span className="viewpass" style={{right: validation.errors.securityPhrase ? "33px" : "11px"}} onClick={togglePassword}>
                                    {passwordShown ? <i className="text-muted far fa-eye" /> : <i className="text-muted far fa-eye-slash" />}
                                  </span>
                                  <label htmlFor="securityPhrase">{validation.errors.securityPhrase ? <span style={{color: "red"}}>{validation.errors.securityPhrase}</span> : "Enter security phrase*"}</label>
                                  <p style={{fontSize: "11px"}}>
                                    * Security phrase is a user specified word/phrase used to verify communication & access between the user and our platform. Only emails or messages with a valid security Phrase must be
                                    considered to be legit. Keep your security Phrase secret and remember to change it frequently.
                                  </p>
                                </div>
                              </Col>
                            </Row>
                          </CardText>
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                  <div className="text-center mt-4">
                    <Button type="submit" color="danger">
                      Update profile
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
          <ToastContainer
        toastStyle={{ backgroundColor: "#f46a6a", color: "white",textAlign:"center"}} 
        position="top-right"
        autoClose={4000}
        hideProgressBar={true}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
        theme="colored"
        limit={3}
      />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(UserProfile);
