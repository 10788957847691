import React, {useCallback, useEffect, useMemo, useRef, useState,} from 'react';
import axios from 'axios';
import {fetchData, getAxiosDefaultConfig, postData,} from '../utils';
import {ApiEndpoint} from '../store/core/endpoint';
import {EntityType} from '../store/core/entityType';
import toastr from 'toastr';
import {Schema} from '../store/core/schema';
import {ObjectType} from '../constants/enums';
import {API_URL} from '../config';
import moment from 'moment';
import {Col} from 'reactstrap'


export function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  React.useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }

    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

const useForm = (initialValues) => {
  const [values, setValues] = useState(initialValues);

  const handleChange = (prop) => (e) => {
    const value = e.target ? e.target.value : e.value || e;
    setValues({
      ...values,
      [prop]: value,
    });
  };
  const handleChangeArray = (prop, array) => {
    setValues({
      ...values,
      [prop]: array,
    });
  };
  const updateInitial = React.useCallback((values) => {
    setValues(values);
  }, []);

  return [values, handleChange, updateInitial, handleChangeArray];
};



const useFetch = (url, initialState, pollTimeout) => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const getData = useCallback((url, callback) => {
    axios
      .get(url, getAxiosDefaultConfig())
      .then((res) => {
        setData(res.data);
        setError(null);
        callback && callback();
      })
      .catch((ex) => {
        setError(ex);
        setData(null);
        callback && callback();
      });
  }, []);

  useEffect(() => {
    getData(url, () => setIsLoading(false));
  }, [url, getData]);

  const pollRef = useRef();
  useEffect(() => {
    clearInterval(pollRef.current);
    if (pollTimeout) {
      pollRef.current = setInterval(() => {
        getData(url);
      }, pollTimeout * 1000);
    }
    return () => {
      clearInterval(pollRef.current);
    };
  }, [pollTimeout, url, getData]);

  if (!url) {
    // Fix for not make requests with invalid url
    // returns the default state
    return [false, initialState, '', setData];
  }
  return [isLoading, data || initialState, error, setData];
};







const typeDelimiter = ';'

const useAppValidation = (loginSuccess, logoutUser) => {
  const [initializing, setInitizalizing] = useState(true);

  const validateUser = React.useCallback(async () => {
    const authUser = localStorage.getItem('authUser');
    if (authUser) {
      try {
        const result = await postData(API_URL + '/auth/status', authUser);
        if (result.valid) loginSuccess(authUser);
        else logoutUser();
        setInitizalizing(false);
      } catch (e) {
        logoutUser();
        setInitizalizing(false);
      }
    } else {
      setInitizalizing(false);
    }
  }, [loginSuccess, logoutUser]);

  React.useEffect(() => {
    validateUser();
  }, [validateUser]);

  return initializing;
};

const usePermission = (context) => {

  const checkPermission = (permission) => {

    const found = context.MyPermissions.getAll.result.find((p) => p.code === permission)
    if (found)
      return found?.code === permission
    else
      return false
  }

  return [checkPermission]
}

const useListToggle = () => {
  const layout = sessionStorage.getItem('table_layout');
  const [isList, toggle] = useToggle(layout === 'LIST');

  useEffect(() => {
    if (isList) {
      sessionStorage.setItem('table_layout', 'LIST');
    } else {
      sessionStorage.setItem('table_layout', 'DEFAULT');
    }
  }, [isList]);

  return [isList, toggle];
};

const useToggle = (initialValue = false) => {
  const [open, setOpen] = useState(initialValue);

  const handleToggle = useCallback(
    () => setOpen((prevState) => !prevState),
    []
  );

  return [open, handleToggle];
};

export {
  useForm,
  useListToggle,
  useToggle,
  useFetch,
  useAppValidation,
  usePermission
};
