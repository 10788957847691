import React from "react";
import { Redirect } from "react-router-dom";

// Profile
import UserProfile from "../pages/Authentication/user-profile";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ResetPassword";
import ResetPwd from "../pages/Authentication/RstPassword";

// Dashboard
import Dashboard from "../pages/Dashboard/index";
import Users from "../pages/Users/index";
import ProfileTab from "../pages/Users/userProfileTab";
import Accommodations from "../pages/Accommodations/index";
import Accommodation from "../pages/Accommodations/accommodation";

// Update
import Update from "../pages/Update/index";

//Tables
import BasicTables from "../pages/Tables/BasicTables";
import DatatableTables from "../pages/Tables/DatatableTables";
import ResponsiveTables from "../pages/Tables/ResponsiveTables";
import EditableTables from "../pages/Tables/EditableTables";
import DragDropTables from '../pages/Tables/DragDropTables';

// Forms
import FormElements from "../pages/Forms/FormElements";
import FormLayouts from "../pages/Forms/FormLayouts";
import FormAdvanced from "../pages/Forms/FormAdvanced";
import FormEditors from "../pages/Forms/FormEditors";
import FormValidations from "../pages/Forms/FormValidations";
import FormMask from "../pages/Forms/FormMask";
import FormRepeater from "../pages/Forms/FormRepeater";
import FormUpload from "../pages/Forms/FormUpload";
import FormWizard from "../pages/Forms/FormWizard";
import FormXeditable from "../pages/Forms/FormXeditable";
import DualListbox from "../pages/Tables/DualListbox";

//Ui
import UiAlert from "../pages/Ui/UiAlert";
import UiButtons from "../pages/Ui/UiButtons";
import UiCards from "../pages/Ui/UiCards";
import UiCarousel from "../pages/Ui/UiCarousel";
import UiColors from "../pages/Ui/UiColors";
import UiDropdown from "../pages/Ui/UiDropdown";
import UiGeneral from "../pages/Ui/UiGeneral";
import UiGrid from "../pages/Ui/UiGrid";
import UiImages from "../pages/Ui/UiImages";
import UiLightbox from "../pages/Ui/UiLightbox";
import UiModal from "../pages/Ui/UiModal";
import UiProgressbar from "../pages/Ui/UiProgressbar";
import UiTabsAccordions from "../pages/Ui/UiTabsAccordions";
import UiTypography from "../pages/Ui/UiTypography";
import UiVideo from "../pages/Ui/UiVideo";
import UiSessionTimeout from "../pages/Ui/UiSessionTimeout";
import UiRating from "../pages/Ui/UiRating";
import UiRangeSlider from "../pages/Ui/UiRangeSlider";
import UiNotifications from "../pages/Ui/ui-notifications";
import UiOffCanvas from "pages/Ui/UiOffCanvas";
import UiBreadcrumb from '../pages/Ui/UiBreadcrumb';
import UiPlaceholders from "../pages/Ui/UiPlaceholders";
import UiToasts from "../pages/Ui/UiToast";
import PagesMaintenance from "../pages/Utility/pages-maintenance";
import PagesComingsoon from "../pages/Utility/pages-comingsoon";
import Pages404 from "../pages/Utility/pages-404";
import Pages500 from "../pages/Utility/pages-500";



const authProtectedRoutes = [
  {path: "/dashboard", component: Dashboard},
  {path: "/properties", component: Accommodations},
  {path: "/property/:id", component: Accommodation},
  {path: "/users", component: Users},
  {path: "/user/:id", component: ProfileTab},
  {path: "/update", component: Update},


  // //profile
  {path: "/profile", component: UserProfile},

  // Tables
  {path: "/tables-basic", component: BasicTables},
  {path: "/tables-datatable", component: DatatableTables},
  {path: "/tables-responsive", component: ResponsiveTables},
  {path: "/tables-editable", component: EditableTables},
  {path: "/tables-dragndrop", component: DragDropTables},

  // Forms
  {path: "/form-elements", component: FormElements},
  {path: "/form-layouts", component: FormLayouts},
  {path: "/form-advanced", component: FormAdvanced},
  {path: "/form-editors", component: FormEditors},
  {path: "/form-mask", component: FormMask},
  {path: "/form-repeater", component: FormRepeater},
  {path: "/form-uploads", component: FormUpload},
  {path: "/form-wizard", component: FormWizard},
  {path: "/form-validation", component: FormValidations},
  {path: "/form-xeditable", component: FormXeditable},
  {path: "/dual-listbox", component: DualListbox},

  // Ui
  {path: "/ui-alerts", component: UiAlert},
  {path: "/ui-buttons", component: UiButtons},
  {path: "/ui-cards", component: UiCards},
  {path: "/ui-carousel", component: UiCarousel},
  {path: "/ui-colors", component: UiColors},
  {path: "/ui-dropdowns", component: UiDropdown},
  {path: "/ui-general", component: UiGeneral},
  {path: "/ui-grid", component: UiGrid},
  {path: "/ui-images", component: UiImages},
  {path: "/ui-lightbox", component: UiLightbox},
  {path: "/ui-modals", component: UiModal},
  {path: "/ui-progressbars", component: UiProgressbar},
  {path: "/ui-tabs-accordions", component: UiTabsAccordions},
  {path: "/ui-typography", component: UiTypography},
  {path: "/ui-video", component: UiVideo},
  {path: "/ui-session-timeout", component: UiSessionTimeout},
  {path: "/ui-rating", component: UiRating},
  {path: "/ui-rangeslider", component: UiRangeSlider},
  {path: "/ui-notifications", component: UiNotifications},
  {path: "/ui-offcanvas", component: UiOffCanvas},
  {path: "/ui-breadcrumb", component: UiBreadcrumb},
  {path: "/ui-placeholders", component: UiPlaceholders},
  {path: "/ui-toasts", component: UiToasts},

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {path: "/", exact: true, component: () => <Redirect to="/dashboard" />},
];

const publicRoutes = [
  {path: "/logout", component: Logout},
  {path: "/login", component: Login},
  {path: "/reset-password", component: ForgetPwd},
  {path: "/reset-password/:uuid", component: ResetPwd},
  {path: "/register", component: Register},

  {path: "/pages-maintenance", component: PagesMaintenance},
  {path: "/pages-comingsoon", component: PagesComingsoon},
  {path: "/pages-404", component: Pages404},
  {path: "/pages-500", component: Pages500},
];

export { authProtectedRoutes, publicRoutes };