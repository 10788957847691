import React from "react";
import avatar from "../../assets/images/users/avatar.png";
import logo from "../../assets/images/users/home.png";
import {Col, Row, Card, CardBody, CardTitle, CardSubtitle, CardImg, CardText, CardHeader, CardImgOverlay, CardFooter, CardDeck, CardColumns, Container} from "reactstrap";
import {Link} from "react-router-dom";
import moment from "moment";
import {locale} from "moment";
moment.locale("el");

const CardContainer = (props) =>
{
  return (
    <Col md={6} lg={4} xl={3}>
      <Card>
      {props.customer ?  
        (<CardBody>
        <div className="form-switch" style={{float:"right"}}>
              <input
                type="checkbox"
                className="form-check-input"
                defaultChecked={props.customer.active}
                onClick={(e) => {
                  props.activateUser(props.customer, e);
                }}
              />
            </div>          
          <Row className="align-items-center">           
            <Col md={2}>
              <img className="img-fluid tableAvatarImg" src={props.customer.avatar != null ? props.customer.avatar : avatar} alt="user" />
            </Col>
           <Col md={10}>
            <Row>
              
                <Col md={12}>
                  <CardTitle>
                    {props.customer.username} -{" "}
                    <small style={{fontWeight:"normal"}}>
                      {props.customer.lastname} {props.customer.firstname}
                    </small>
                  </CardTitle>
                </Col>             
                <Col md={12}>
                  <CardText>{props.customer.email}</CardText>
                </Col>
            </Row>
           </Col>
          </Row>
          </CardBody>)
          
          :
          (<CardBody style={{backgroundColor: props.property ? props.property.color : '#fff', color: (props.property.color ==="#ffffff" || props.property.color === null || props.property.color === undefined )? "#74788D" : '#fff',}}>
            <div className="form-switch" style={{float:"right"}}>
                  <input
                    type="checkbox"
                    className="form-check-input"
                    defaultChecked={props.property.active}
                    onClick={(e) => {
                      props.activateProperty(props.property, e);
                    }}
                  />
                </div>          
              <Row className="align-items-center">           
                <Col md={2}>
                {/* <img className="img-fluid tableAvatarImg" src={props.property.logo != null ? props.customer.logo : logo} alt="property" /> */}
                <i className="bx bx-tone bx-sm"></i>
                </Col>
               <Col md={10}>
                <Row>
                  
                    <Col md={12}>
                      <CardTitle>
                        {props.property.name}
                        <small style={{fontWeight:"normal"}}>
                       
                        </small>
                      </CardTitle>
                    </Col>             
                    <Col md={12}>
                      <CardText></CardText>
                    </Col>
                </Row>
               </Col>
              </Row>
              </CardBody>)
      }
      </Card>
    </Col>
  );
};

export default CardContainer;
