import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
} from "reactstrap";
import Select from "react-select";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";
import LanguageDropdown from "../../components/CommonForBoth/TopbarDropdown/LanguageDropdown";
import { withRouter } from "react-router-dom";
import {getAxiosDefaultConfig, getId } from '../../utils';
import axios from "axios";
import {API_URL} from '../../config';
//Import Breadcrumb
import Dropzone from "react-dropzone"
import avatar from "../../assets/images/users/avatar.png";
// actions
import { editProfile, resetProfileFlag } from "../../store/actions";
import { Link } from "react-router-dom"
import moment from "moment";
import {locale} from "moment";
moment.locale('el')


const ProfileTab = () => {
const [error,setError]= useState(true)
   //meta title
   document.title="Profile | Admin Zakynthos Luxury Villas";


  const [currentUser, setCurrentUser] = useState({firstname:"",lastname:""})
  const [selectedFiles, setselectedFiles] = useState([])

  const getUserInfo = async () =>  {
    const url = API_URL + "/users/"+getId();
    await axios
        .get(url, getAxiosDefaultConfig())
        .then((response) => {
          setCurrentUser(response.data)
          //return response.data;
        })
        .catch(error =>
        {
          console.log(error.response.data.error)
        }); 
 }
 
  useEffect(() => {    
    getUserInfo()
  },[]) 

    function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
      setselectedFiles(files)
  }

  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }


  const submitForm = async(values) =>
  {
    const url = API_URL + "/users/"+getId();
    const axiosConfig = getAxiosDefaultConfig();
    const formData = new FormData();
    const data = {...values,...selectedFiles[0]};
     formData.append("file", selectedFiles[0]);
   

     formData.append("data", JSON.stringify(data));
      try{  
        await axios
          .put(url, formData, getAxiosDefaultConfig())
          .then((res) => res.data)
          .then(getUserInfo())
          .catch(error =>
          {
            console.log(error)
          }); 
          }
      catch (error) {
            console.log(error.response.data.error)
          }    
   

  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      firstname: currentUser.firstname,
      lastname: currentUser.lastname,
      username: currentUser.username,
      email: currentUser.email,
      id: currentUser.id,
      password: null,
      avatar: currentUser.avatar,
      active: currentUser.active,
      roles: currentUser.roles
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Please Enter Your UserName"),
      firstname: Yup.string().nullable(),
      lastname: Yup.string().nullable(),
      active: Yup.number(),
      roles: Yup.number(),      
      id: Yup.number(),      
      email: Yup.string().email(),
      avatar: Yup.string().nullable()
    }),
    onSubmit: (values) => {
      submitForm(values)
    }
  });

  const optionGroup = [
  {label: "Webmaster", value: parseInt(process.env.REACT_APP_WEBMASTER) },  
  {label: "Admin", value: parseInt(process.env.REACT_APP_ADMIN)},
  {label: "Owner", value: parseInt(process.env.REACT_APP_OWNER)},
  {label: "Guest", value: parseInt(process.env.REACT_APP_GUEST)},
];
  
const optionGroup1 = [  
      { label: "Active", value: 1 },
      { label: "Disabled", value: 0 }
  ];
  
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          {/* <Breadcrumb title="Event Admin" breadcrumbItem="Profile" /> */}

          <Row>
            <Col lg="8">
              {/* {error && error ? <Alert color="danger">{error}</Alert> : null}
              {success ? <Alert color="success">{success}</Alert> : null} */}

              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="ms-3 text-center">
                      <img src={currentUser.avatar ? currentUser.avatar : avatar} alt="user image" className="avatar-md rounded-circle img-thumbnail" />
                      {/* <p className="mb-0">Id: {currentUser.id}</p> */}
                    </div>
                    <div className="flex-grow-1 align-self-center">
                      <div className="text-muted">
                        <h4 className="mb-1">{currentUser.username}</h4>
                        <p className="mb-0">Member since {moment(currentUser.created).format("dddd Do MMMM YYYY")}</p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            {/* <Col lg="4">
             <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="ms-3 text-center">
                      <p className="mb-0">Select language</p>
                      <LanguageDropdown />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>             */}
          </Row>

          <h4 className="card-title mb-4">User Information</h4>
          <Col lg="8">
            <Card>
              <CardBody>
                <Form
                  className="form-horizontal"
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}>
                  <div className="form-group">
                    <Label className="form-label">Email</Label>
                    <Input name="email" value={currentUser.email} className="form-control" type="text" disabled />
                  </div>
                  <div className="form-group">
                    <Label className="form-label">Password</Label>
                    <Input name="email" value={currentUser.password} className="form-control" type="password" />
                  </div>
                  <div className="form-group">
                    <Label className="form-label">Username</Label>
                    <Input
                      name="username"
                      className="form-control"
                      placeholder="Enter User Name"
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.username || ""}
                      invalid={validation.touched.username && validation.errors.username ? true : false}
                    />
                    {validation.touched.username && validation.errors.username ? <FormFeedback type="invalid">{validation.errors.username}</FormFeedback> : null}
                    <Input name="id" value={currentUser.id} type="hidden" />
                  </div>

                  <div className="form-group">
                    <Label className="form-label">First Name</Label>
                    <Input name="firstname" value={validation.values.firstname || ""} onChange={validation.handleChange} onBlur={validation.handleBlur} className="form-control" type="text" />
                  </div>

                  <div className="form-group">
                    <Label className="form-label">Last Name</Label>
                    <Input name="lastname" value={validation.values.lastname || ""} onChange={validation.handleChange} onBlur={validation.handleBlur} className="form-control" type="text" />
                  </div>
                  {(currentUser.roles === process.env.REACT_APP_WEBMASTER ||currentUser.roles === process.env.REACT_APP_ADMIN) && (
                    <>
                      <div div className="form-group">
                        <Label className="form-label">Active user</Label>
                        <select name="active" value={validation.values.active} onChange={validation.handleChange} onBlur={validation.handleBlur} className="form-control">
                          <option value="null" disabled>
                            Select
                          </option>
                          {optionGroup1.map((role) => {
                            return (
                              <option key={role.value} value={role.value}>
                                {role.label}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div div className="form-group">
                        <Label className="form-label">User role</Label>
                        <select name="roles" value={validation.values.roles} onChange={validation.handleChange} onBlur={validation.handleBlur} className="form-control">
                          <option value="null" disabled>
                            Select
                          </option>
                          {optionGroup.map((role) => {
                            if (role.value >= currentUser.roles)
                              return (
                                <option key={role.value} value={role.value}>
                                  {role.label}
                                </option>
                              );
                          })}
                        </select>
                      </div>
                    </>
                  )}
                  <div className="form-group">
                    <Label className="form-label">Upload Avatar</Label>
                    <Dropzone
                      onDrop={(acceptedFiles) => {
                        handleAcceptedFiles(acceptedFiles);
                      }}>
                      {({getRootProps, getInputProps}) => (
                        <div className="dropzone">
                          <div className="dz-message needsclick mt-2" {...getRootProps()}>
                            <input {...getInputProps()} />
                            <div className="mb-3">
                              <i className="display-4 text-muted bx bxs-cloud-upload" />
                            </div>
                            <h4>Drop files here or click to upload.</h4>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                    <div className="dropzone-previews mt-3" id="file-previews">
                      {selectedFiles.map((f, i) => {
                        return (
                          <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete" key={i + "-file"}>
                            <div className="p-2">
                              <Row className="align-items-center">
                                <Col className="col-auto">
                                  <img data-dz-thumbnail="" height="80" className="avatar-sm rounded bg-light" alt={f.name} src={f.preview} />
                                </Col>
                                <Col>
                                  <Link to="#" className="text-muted font-weight-bold">
                                    {f.name}
                                  </Link>
                                  <p className="mb-0">
                                    <strong>{f.formattedSize}</strong>
                                  </p>
                                </Col>
                              </Row>
                            </div>
                          </Card>
                        );
                      })}
                    </div>
                  </div>

                  <div className="text-center mt-4">
                    <Button type="submit" color="danger">
                      Update user
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ProfileTab);
