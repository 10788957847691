import React from "react";
import {Card, CardBody, CardTitle, Col, Container, Row} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {API_URL} from "../../config";
import axios from "axios";
import {getAxiosDefaultConfig} from "../../utils";
import {toast} from "react-toastify";


const Update = props => {

  const fetchProperties = ()=> {

    let config = {
      headers: {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        'Accept': 'application/json',
        'Authorization': 'Basic dGhlYW5vX2tvczpFODVEODlCQjk5RERGQzQ2MkMzNTRDOTU4MTJBMDRCODMyOUIzMENG'
      }
    };

    const url = "https://rest.reserve-online.net/property";
    axios
      .get(url, config)
      .then((response) => {
        setCustomerList(response.data.users);
        setFilteredCustomerList(response.data.users);
      })
      .catch((error) => {
        console.log(error.response.data.error);
        toast.error(error.response.data.error);
      });

    // const myHeaders = new Headers();
    // myHeaders.append("Content-Type", "application/json");
    // myHeaders.append("User-Agent", "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/103.0.0.0 Safari/537.36");
    // myHeaders.append("Accept", "application/json");
    // myHeaders.append("Authorization", "Basic dGhlYW5vX2tvczpFODVEODlCQjk5RERGQzQ2MkMzNTRDOTU4MTJBMDRCODMyOUIzMENG");
    // myHeaders.append("Host", "https://www.zakynthos-luxury-villas.com");
    // myHeaders.append("referer", "https://www.zakynthos-luxury-villas.com");
    //
    // const requestOptions = {
    //   method: "GET",
    //   headers: myHeaders,
    //   redirect: "follow"
    // };
    //
    // fetch("https://rest.reserve-online.net/property", requestOptions)
    //   .then((response) => response.text())
    //   .then((result) => console.log(result))
    //   .catch((error) => console.error(error));

  }


  return (<>
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs className="breadcrumbs" title="Update properties" />
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <Row>
                  <Col lg={4}>

                    <div className="col-lg-12 mb-4">
                      <h5 className="font-size-15 mb-3">Fetch new properties</h5>
                      <div className="d-flex flex-wrap gap-2">
                        <button
                          type="button"
                          className="btn btn-primary  w-sm"
                          onClick={fetchProperties}
                        >
                          <i className="mdi mdi-download  font-size-16"></i>{" "}
                          Fetch
                        </button>
                      </div>
                    </div>

                    <div className="col-lg-12 mb-4">
                      <h5 className="font-size-15 mb-3">Download new properties</h5>
                      <div className="d-flex flex-wrap gap-2">
                        <button
                          type="button"
                          className="btn btn-light  w-sm"
                        >
                          <i className="mdi mdi-download  font-size-16"></i>{" "}
                          Download
                        </button>
                      </div>
                    </div>


                  </Col>
                  <Col lg={8}>

                    <div className="col-lg-12 mt-8">

                      jmcghjch
                    </div>

                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

    </div>

  </>);
};

export default Update;