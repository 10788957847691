import React, {useEffect, useState, useMemo} from "react";
import {Card, CardBody, Col, Container, Row, Modal, ModalHeader, ModalBody, Button} from "reactstrap";

import {useListToggle, useToggle} from "../../helpers/hooks";

import Breadcrumbs from "../../components/Common/Breadcrumb";

import {API_URL} from "../../config";
import axios from "axios";
import {getAxiosDefaultConfig} from "../../utils";
import avatar from "../../assets/images/users/avatar.png";

import DeleteModal from "../../components/Common/DeleteModal";

import TableContainer from "../../components/Common/TableContainer";

import CardContainer from "../../components/Common/CardContainer";

import UserEditNew from "../../components/Common/UserEditNewForm";

import {ToastContainer, toast} from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import moment from "moment";
import {locale} from "moment";
import {error} from "toastr";
import {toLower} from "lodash";
moment.locale("el");

const Users = (props) => {
  //meta title
  document.title = "Users | Admin Zakynthos Luxury Villas";

  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [showList, toggleListLayout] = useListToggle();
  const [user, setUser] = useState({});
  const [customerList, setCustomerList] = useState([]);
  const [filteredCustomerList, setFilteredCustomerList] = useState([]);
  const [editUser, setEditUser] = useState({});
  const [deleteUser, setDeleteUser] = useState({});
  const [hasResponse, setHasResponse] = useState();
  const [userActionBtn, setUserActionBtn] = useState({});

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    setUser(user);
    getUserList();
  }, []);

  useEffect(() => {
    toast(hasResponse);
  }, [hasResponse]);

  useEffect(() => {
    toast(userActionBtn);
  }, [userActionBtn]);

  useEffect(() => {
    setFilteredCustomerList(customerList);
  },[showList])  

  const getUserList = () => {
    const url = API_URL + "/users/all";
    axios
      .get(url, getAxiosDefaultConfig())
      .then((response) => {
        setCustomerList(response.data.users);
        setFilteredCustomerList(response.data.users);
      })
      .catch((error) => {
        console.log(error.response.data.error);
        toast.error(error.response.data.error);
      });
  };

  const activateUser = async (value, e) => {
    setHasResponse(null);
    const data = {
      ...value,
      ...value.user_rights.menu_options,
      ...value.user_rights.order_options,
      ...value.user_rights.schedule_options,
      ...value.user_rights.accommodation_options,
      user_rights: undefined,
      active: e.target.checked ? 1 : 0,
    };
    const formData = new FormData();
    formData.append("data", JSON.stringify(data));
    const url = API_URL + "/users/" + data.id;
    try {
      await axios
        .put(url, formData, getAxiosDefaultConfig())
        //.then((res) => res.data)
        .then((res) => {
          setHasResponse(res.data.message);
          getUserList()
        })
        .catch((error) => {
          console.log(error);
          setHasResponse(error.response.data.message);
        });
    } catch (error) {
      console.log(error.response.data.error);
      setHasResponse(error.response.data.message);
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "#",
        // accessor: 'id',
        disableFilters: true,
      },
      {
        Header: "Avatar ",
        accessor: "avatar",
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <div>
              <img src={cellProps.value != null ? cellProps.value : avatar} alt="avatar" width="40" height="40" className="tableAvatarImg" />
            </div>
          );
        },
      },
      {
        Header: "First Name ",
        accessor: "firstname",
        disableFilters: true,
      },
      {
        Header: "Last Name ",
        accessor: "lastname",
        disableFilters: true,
      },
      {
        Header: "Email ",
        accessor: "email",
        disableFilters: true,
      },
      {
        Header: "Username",
        accessor: "username",
        disableFilters: true,
      },
      {
        Header: "Created at ",
        accessor: "created",
        disableFilters: true,
        Cell: (cellProps) => {
          return <div>{moment(cellProps.value).format("DD/MM/YYYY").toString()}</div>;
        },
      },
      {
        Header: "Role ",
        accessor: "roles",
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <div>
              {cellProps.value === parseInt(process.env.REACT_APP_WEBMASTER)
                ? "Webmaster"
                : cellProps.value === parseInt(process.env.REACT_APP_ADMIN)
                ? "Admin"
                : cellProps.value === parseInt(process.env.REACT_APP_OWNER)
                ? "Owner"
                : "Guest"}{" "}
            </div>
          );
        },
      },
      {
        Header: "Active ",
        accessor: "active",
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <div className="form-switch">
              <input
                type="checkbox"
                className="form-check-input"
                //onChange={validation.handleChange}
                // onBlur={validation.handleBlur}
                // defaultValue={cellProps.value}
                defaultChecked={cellProps.value}
                onClick={(e) => {
                  activateUser(cellProps.row.original, e);
                }}
              />
            </div>
          );
        },
      },
      {
        Header: "Actions ",
        accessor: "action",
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <div className="gap-3">
              <button
                type="button"
                className="btn btn-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleEditClick(userData);
                }}>
                <i className="mdi mdi-pencil font-size-13"></i>
              </button>{" "}
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => {
                  const userData = cellProps.row.original;
                  onClickDelete(userData);
                }}>
                <i className="mdi mdi-trash-can font-size-13"></i>
              </button>
            </div>
          );
        },
      },
    ],
    [],
  );

  const toggle = () => {
    if (modal) {
      setModal(false);
      setEditUser({});
    } else {
      setModal(true);
    }
  };

  //delete user
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (user) => {
    setDeleteModal(true);
    setDeleteUser(user);
  };

  const handleDeleteUser = async () => {
    const url = API_URL + "/users/" + deleteUser.id;
    try {
      await axios
        .delete(url, getAxiosDefaultConfig())
        .then((res) => {
          getUserList();
          setHasResponse(res.data.message);
          setDeleteModal(false);
          setDeleteUser({});
        })
        .catch((error) => {
          console.log(error);
          setHasResponse(error.response.data.message);
          setDeleteModal(false);
          setDeleteUser({});
        });
    } catch (error) {
      console.log(error.response.data.error);
      setHasResponse(error.response.data.message);
      setDeleteModal(false);
      setDeleteUser({});
    }
  };

  const handleEditClick = (arg) => {
    const user = arg;
    setEditUser({
      firstname: user.firstname,
      lastname: user.lastname,
      username: user.username,
      email: user.email,
      id: user.id,
      password: null,
      avatar: user.avatar,
      active: user.active,
      roles: user.roles,
      created: user.created,
      address: user.address,
      pc: user.pc,
      city: user.city,
      region: user.region,
      country: user.country,
      phone: user.phone,
      contactasbilling: user.contactasbilling,
      billing_name: user.billing_name,
      billing_address: user.billing_address,
      billing_pc: user.billing_pc,
      billing_city: user.billing_city,
      billing_region: user.billing_region,
      billing_country: user.billing_country,
      billing_vat: user.billing_vat,
      billing_doy: user.billing_doy,
      billing_phone: user.billing_phone,
      ttl: user.ttl,
      lastLogin: user.lastLogin,
    });

    setIsEdit(true);

    toggle();
  };

  const handleAddUserClick = () => {
    setEditUser({});
    setIsEdit(false);
    toggle();
  };

  const filterUserList = (e) => {
    const term = e.target.value;
    if (term.length >= 2) {
      const newCustomerList = [...customerList];

      const newArray = newCustomerList.filter((obj) => {
        return Object.values(obj).some((value) => {
          return typeof value === "string" && value.toLowerCase().includes(term.toLowerCase());
        });
      });

      setFilteredCustomerList(newArray);
    } else if (term.length === 0) {
      setFilteredCustomerList(customerList);
    }
  };

  return (
    <React.Fragment>
      <DeleteModal show={deleteModal} onDeleteClick={handleDeleteUser} onCloseClick={() => setDeleteModal(false)} />
      <div className="page-content">
        <Breadcrumbs title="" showList={showList} toggleListLayout={toggleListLayout} />
        <Container fluid>
          <Row>
            <Col xs="12">
              {showList && (
                <Card>
                  <CardBody>
                    <TableContainer
                      columns={columns}
                      data={customerList}
                      isGlobalFilter={true}
                      isAddUserList={true}
                      handleUserClick={handleAddUserClick}
                      customPageSize={25}
                      customPageSizeOptions={false}
                      className="custom-header-css"
                    />
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>
          <Row>
            {!showList && (
              <>
                <Col lg={4}>
                  <div className="search-box d-inline-block" style={{width: "100%"}}>
                    <div className="position-relative">
                      <label htmlFor="search-bar-0" className="search-label" style={{width: "100%"}}>
                        <span id="search-bar-0-label" className="sr-only">
                          Search users
                        </span>
                        <input
                          id="search-bar-0"
                          type="text"
                          className="form-control"
                          name="containerSearch"
                          placeholder={`${customerList.length} records...`}
                          onChange={(e) => {
                            filterUserList(e);
                          }}
                        />
                      </label>
                      <i className="bx bx-search-alt search-icon"></i>
                    </div>
                  </div>
                </Col>
                <Col lg={8}>
                  <div className="text-sm-end">
                    <Button disabled={user.maxClients >= customerList.length ? false : true} type="button" color="primary" className="btn mb-4" onClick={handleAddUserClick}>
                      <i className="mdi mdi-plus-circle-outline me-1" />
                      Create New User
                    </Button>
                  </div>
                </Col>
              </>
            )}
            {!showList &&
              filteredCustomerList.map((customer, index) => {
                return (
                  <CardContainer
                    key={index}
                    hasClientLimit={user.maxClients >= customerList.length ? false : true}
                    customer={customer}
                    isGlobalFilter={true}
                    isAddUserList={true}
                    handleUserClick={handleAddUserClick}
                    className="custom-header-css"
                    activateUser={activateUser}
                  />
                );
              })}
          </Row>
        </Container>
        <Modal isOpen={modal} toggle={toggle} size={"lg"} className="modal-dialog">
          <ModalHeader toggle={toggle} tag="h4">
            {!!isEdit ? "EDIT USER" : "ADD NEW USER"}
          </ModalHeader>
          <ModalBody>
            <UserEditNew user={editUser} toggle={toggle} currentUser={user} getUserList={getUserList} isEdit={isEdit} setHasResponse={setHasResponse} />
          </ModalBody>
        </Modal>
      </div>
      <ToastContainer
        toastStyle={{backgroundColor: "#f46a6a", color: "white", textAlign: "center"}}
        position="top-right"
        autoClose={4000}
        hideProgressBar={true}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
        theme="colored"
        limit={3}
      />
    </React.Fragment>
  );
};

export default Users;
