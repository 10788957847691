import React from "react";
import {Link} from "react-router-dom";
import 
{
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  CardTitle,
  CardText,
  BreadcrumbItem
} from "reactstrap";

const Breadcrumb = (props) => {
  return (
    <Row>
      <Col xs="12" className="m-1">
        <div className="page-title-box d-sm-flex align-items-center justify-content-between p-0">
          <Card>
            <CardBody>
          <h4 className="mb-0 font-size-18">{props.title}</h4>

            </CardBody>
          </Card>
          {/*<div className="page-title-right">*/}
          {/*  <ol className="breadcrumb m-0">*/}
          {/*    <BreadcrumbItem>*/}
          {/*      <Link to="/properties">{props.title}</Link>*/}
          {/*    </BreadcrumbItem>*/}
          {/*    <BreadcrumbItem active>*/}
          {/*      <Link to="#">{props.breadcrumbItem}</Link>*/}
          {/*    </BreadcrumbItem>*/}
          {/*  </ol>*/}
          {/*</div>*/}
          {props.toggleListLayout && (
            <div className="page-title-right m-0">
              <button type="button" onClick={props.toggleListLayout} className="btn font-size-18" data-toggle="fullscreen">
                {!props.showList && <i className="bx bx-list-ul" />}
                {props.showList && <i className="bx bx-grid" />}
              </button>
            </div>
          )}
        </div>
      </Col>
    </Row>
  );
};

export default Breadcrumb;
